<template>
  <div class="tt-contact-us">
    <div>
      <div class="tt-contact-us__title ff-mont"
           v-if="forPartners">
        {{ $t('about_partners') }}
      </div>
      <div v-else>
        <div class="tt-contact-us__title ff-mont">
          {{ $t('navbar_contacts') }}
        </div>
        <div class="tt-contact-us__contacts">
          <div>{{ $t('about_component_vacancy_input_phone') }}:</div>
          <div>+7 (771) 555 60 60 - call center для заказа</div><br />
          <div>+7 (705) 755 01 36 - HR отдел завода</div><br />
          <div>+7 (771) 103 70 32 - HR отдел филиалов</div>
        </div>
        <br />
        <div class="tt-contact-us__contacts">
          <div>E-mail:</div>
          <div>feedback@taptatti.kz</div><br />
          <div>info@taptatti.kz</div>
        </div>
      </div>
    </div>

    <form class="tt-contact-us__form"
          v-if="!formSent">
      <div class="tt-contact-us__subtitle">
        <span v-if="!forVacancy">
        {{ $t('Contact_us_form_default_subtitle') }}
        </span>
        <span v-else>
        {{ $t('Contact_us_form_for_vacancy_subtitle') }}
        </span>
      </div>

      <div class="tt-input-field">
        <label class="tt-input-field__label">
          {{ $t('about_component_partners_input_name') }}
        </label>
        <input class="tt-input-field__input"
               :class="{ 'tt-input-field__input--invalid': isInvalid('name') }"
               :placeholder="$t('about_component_partners_placeholder_name')"
               v-model="form.name">
        <div class="tt-input-field__error">
          {{ showError(errors.name) }}
        </div>
      </div>

      <div class="tt-input-field">
        <label class="tt-input-field__label">
          {{ $t('about_component_partners_input_email') }}
        </label>
        <input class="tt-input-field__input"
               :class="{ 'tt-input-field__input--invalid': isInvalid('email') }"
               :placeholder="$t('about_component_partners_placeholder_email')"
               v-model="form.email">
        <div class="tt-input-field__error">
          {{ showError(errors.email) }}
        </div>
      </div>

      <div class="tt-input-field">
        <label class="tt-input-field__label">
          {{ $t('about_component_partners_input_phone') }}
        </label>
        <input type="tel"
               class="tt-input-field__input"
               :class="{ 'tt-input-field__input--invalid': isInvalid('phone') }"
               v-mask="'+7 (###) ###-##-##'"
               placeholder="+7 (___) ___ __ __"
               v-model="form.phone">
        <div class="tt-input-field__error">
          {{ showError(errors.phone) }}
        </div>
      </div>

      <div class="tt-input-field">
        <label class="tt-input-field__label">
          {{ $t('about_component_partners_input_description') }}
        </label>
        <textarea class="tt-input-field__input tt-textarea"
                  :class="{ 'tt-input-field__input--invalid': isInvalid('message') }"
                  v-model="form.message" />
        <div class="tt-input-field__error">
          {{ showError(errors.message) }}
        </div>
      </div>

      <div v-if="forVacancy"
           class="tt-contact-us__file-input-field">
        <input type="file"
               id="cv-file-input"
               class="tt-contact-us__file-input"
               ref="formGroupFile"
               @change="uploadFile" />
        <div class=""></div>
        <label for="cv-file-input"
               class="tt-contact-us__file-input-label">
          <img src="@/assets/icons-new/photo.svg" alt="img">
          <span v-if="file && file.name">
            {{ file.name }}
          </span>
          <span v-else>
            {{ $t('contacts_widget_upload_resume') }}
          </span>
        </label>
        <div v-show="file"
             class="tt-icon-button"
             @click="file = null">
          <img src="@/assets/icons-new/close_blue.svg">
        </div>
      </div>

      <button class="tt-button tt-button--primary"
              :disabled="loading"
              @click.prevent="submit">
        {{ $t('contacts_widget_btn2send') }}
      </button>
    </form>

    <div class="tt-contact-us__success"
         v-else>
      <p>{{ $t('contacts_widget_success_message') }}</p>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { phoneValidator } from "@/mixins/validators";

export default {
  props: {
    forVacancy: false,
    forPartners: false
  },
  data: () => ({
    city: JSON.parse(localStorage.getItem('city')) || {},
    form: {},
    errors: {},
    loading: false,
    formSent: false,
    file: null
  }),
  validations() {
    return {
      form: {
        phone: {
          required,
          phoneValidator
        },
        email: {
          required
        },
        name: {
          required
        },
        message: {
          required
        }
      },
    }
  },
  methods: {
    isInvalid(prop) {
      return this.$v.form[prop].$dirty && this.$v.form[prop].$invalid;
    },
    showError(err) {
      return typeof err === 'object' ? err[0] : err;
    },
    uploadFile(){
      this.file = this.$refs.formGroupFile.files[0];
      this.$refs.formGroupFile.value = null;
    },
    async submit() {
      if (this.$v.form.$invalid && this.city) {
        this.$v.form.$touch();
        return;
      }

      this.loading = true;
      let formData = new FormData();

      Object.keys(this.form).forEach(item => {
        formData.append(item, this.form[item]);
      });
      if (this.file) formData.append('file', this.file);
      if (this.city) formData.append('city_id', this.city.id);

      if (this.forPartners) {
        formData.append('type', 'suggestion');
      } else if (this.forVacancy) {
        formData.append('type', 'vacancy');
      } else {
        formData.append('type', 'contacts');
      }

      try {
        await this.$axios.post( this.forVacancy ? "/vacancy/resume" : "/feedback", formData);
        this.formSent = true;
      } catch (e) {
        console.error(e.response.data);
        this.errors = (e.response.data || {}).errors;
        if (this.errors.file !== undefined) {
          this.$toast.open({
            position: 'top-right',
            type: 'error',
            message: this.errors.file[0],
            duration: 3000,
          });
        }
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"
@import "src/assets/styles-new/colors"

.tt-contact-us
  display: flex
  justify-content: space-between
  gap: 32px
  &__title
    font-size: $fs-larger
    line-height: 1.2em
    font-weight: $fw-semibold
    margin-bottom: 24px
  &__subtitle
    margin-bottom: 16px
    font-size: $fs-small
  &__contacts
    display: grid
    grid-template-columns: 100px 1fr
    font-weight: $fw-medium
  &__form
    width: 100%
    max-width: 544px
    .tt-input-field
      margin-bottom: 10px
    .tt-button
      height: 56px
    .tt-textarea
      height: 140px
  &__file-input-field
    display: flex
    align-items: center
    margin-bottom: 24px
    .tt-icon-button
      height: 16px
      margin-left: 20px
  &__file-input
      display: none
  &__file-input-label
    cursor: pointer
    margin-bottom: 0
    display: flex
    align-items: center
    gap: 10px
  &__success
    margin-top: 8px
    color: $main
    font-weight: $fw-medium

@media screen and (max-width: $tablet-width)
  .tt-contact-us
    &__title
      font-size: $fs-large

@media screen and (max-width: $mobile-width)
  .tt-contact-us
    flex-direction: column
    gap: 0
    &__title
      font-size: $fs-semilarge
      margin-bottom: 16px
    &__contacts
      font-size: $fs-small
      margin-bottom: 40px
</style>